import Link from "next/link";
import React from "react";

import css from "./index.module.scss";

export default function Tag({
  label = "",
  className = "",
  light = false,
  onClick = null,
  href = "",
}) {
  let thisClassName = `${className} ${css.tag}`;
  if (light){
    thisClassName += ` light ${css.light}`;
  }

  if (onClick || href){
    thisClassName += ` ${css.clickable}`;
  }

  if (href){
    return <Link href={href} >
      <a className={`custom ${thisClassName}`} onClick={() => {
        if (onClick){
          onClick(label);
        }
      }}>{label}</a>
    </Link>;
  }

  return <div className={thisClassName} onClick={() => {
    if (onClick){
      onClick(label);
    }
  }}>{label}</div>;
}
