import React, { useState, useRef } from "react";
import FloatingTextInput from "@/components/floating-text-input";
import Button from "@/components/button";
import css from "./index.module.scss";
import { sendDatalayerEvent } from "@/scripts/utils";

export default function RequestPromptForm({
  initialCta = "Request Prompt",
  light = true,
}) {
  const [form, setForm] = useState({
    name: useRef(),
    email: useRef(),
    message: useRef(),
  });
  const [cta, setCta] = useState(initialCta);
  const [allowSubmit, setAllowSubmit] = useState(true);

  async function submit() {
    if (allowSubmit || true) {
      setAllowSubmit(false);
      let allValid = true;
      for (const [key, element] of Object.entries(form)) {
        if (!await element.current.isValid()) {
          allValid = false;
        }
      }

      if (allValid) {
        setCta("Submitting");
        fetch("/api/request-prompt", {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            name: form.name.current.getValue(),
            email: form.email.current.getValue(),
            message: form.message.current.getValue(),
          }),
        })
          .then((res) => {
            if (res.ok) {
              setCta("Sent!");
              sendDatalayerEvent("requestPrompt", "DL - Request Prompt", null);
            } else {
              setCta("Error");
              setAllowSubmit(true);
            }
          });
      } else {
        setAllowSubmit(true);
      }
    }
  }


  return (<div className={css.form}>
    <FloatingTextInput
      label="Name"
      ref={form.name}
      light={light}
      validators={[
        { test: (val) => /^$/.test(val), class: "invalid", message: "Required" },
        { test: (val) => /^..*$/.test(val), class: "valid", message: "" },
      ]}
    />
    <FloatingTextInput
      label="Email"
      ref={form.email}
      light={light}
      validators={[
        { test: (val) => /^$/.test(val), class: "invalid", message: "Required" },
        { test: (val) => /^..*@.*$/.test(val), class: "valid", message: "" },
        { test: (val) => /.*/.test(val), class: "invalid", message: "Must have an @" },
      ]}
    />
    <FloatingTextInput
      multiline
      label="Message"
      ref={form.message}
      light={light}
      validators={[
        { test: (val) => /^$/.test(val), class: "invalid", message: "Required" },
        { test: (val) => /^(.|\n)*$/.test(val), class: "valid", message: "" },
      ]}
    />

    <Button label={cta} onClick={submit} className={css.button} disabled={!allowSubmit}/>
  </div>);
}



