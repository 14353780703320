import React, { useState } from "react";


export function StrapiPicture({picture, alt = "", loading = "lazy", sizes = "", backgroundColor = "black"}) {
  let srcsets = [];
  if (picture?.formats){
    for (const [key, format] of Object.entries(picture.formats)){
      let url = format.url;
      if (url.startsWith("/")){
        url = process.env.NEXT_PUBLIC_API_URL + url;
      }
      srcsets.push(`${url} ${format.width}w`);
    }
  }

  return (
    <picture style={{backgroundColor: backgroundColor}}>
      {srcsets.length > 0 && <source sizes={sizes} srcSet={srcsets.join(",")} type={`image/${picture?.ext.replace(".", "")}`}></source>}
      <img src={picture?.url} alt={alt} loading={loading}/>
    </picture>
  );
}



export default function Picture({base, alt = "", exts, loading, widths, background = "#00000000", sizes = ""}) {
  if (exts[0] === "svg"){
    return (
      <img
        style={{backgroundColor: background}}
        src={`${base}.svg`}
        alt={alt}
        loading={loading}
      />
    );
  }
  return (
    <picture style={{backgroundColor: background}}>
      { exts.map((ext) => {
        let srcset = widths.map((width) => {
          return `${base }-${ width }w.${ ext } ${ width }w,`;
        }).join("");
        return <source key={ext} srcSet={srcset} sizes={sizes} type={`image/${ext}`}></source>;
      })}
      <img src={`${base}.${exts[exts.length - 1]}`} alt={alt} loading={loading}/>
    </picture>
  );
}
