import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import css from "./index.module.scss";

export default forwardRef(({
  ghost = false,
  light = false,
  grayscale = false,
  href = "",
  onClick = () => {},
  onMouseDown = () => {},
  label = "",
  className = "",
  disabled = false,
  icon = null,
  target = "_self",
  notice = "",
  large = false,
  danger = false,
  iconAfter = null,
}, ref) => {
  let thisClassName = `${className} ${css.button}`;
  if (ghost){
    thisClassName += ` ${css.ghost}`;
  }
  if (light){
    thisClassName += ` ${css.light}`;
  }
  if (grayscale){
    thisClassName += ` ${css.grayscale}`;
  }
  if (icon && !label){
    thisClassName += ` ${css.iconOnly}`;
  }
  if (large){
    thisClassName += ` ${css.large}`;
  }
  if (danger){
    thisClassName += ` ${css.danger}`;
  }

  function handleClick(event){
    onClick(event);
  }

  if (href){
    return <div className={thisClassName}>
      <a
        ref={ref}
        className={"custom"}
        onClick={(event) => {
          if (disabled){
            event.stopPropagation();
            event.preventDefault();
          } else {
            handleClick(event);
          }
        }}
        onMouseDown={onMouseDown}
        href={href}
        disabled={disabled}
        target={target}
      >
        <div className={css.label}>{icon} {label && <span>{label}</span>} {iconAfter}</div>
      </a>
      {notice && <div className={css.notice}>{notice}</div>}
    </div>;
  }

  return <div className={thisClassName}>
    <button
      ref={ref}
      onClick={handleClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
    >
      {icon}
      {label && <span>{label}</span>}
      {iconAfter}
    </button>
    {notice && <div className={css.notice}>{notice}</div>}
  </div>;
});
