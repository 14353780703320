import React, {useState, useImperativeHandle, forwardRef} from "react";
import MagnifyingGlassSvg from "/public/images/icons/magnifyingGlass.svg";
import CircleArrowRight from "/public/images/icons/circleArrowRight.svg";
import Button from "../button";
import css from "./index.module.scss";
import { useEffect, useRef } from "react";


export default forwardRef(({
  onChange = () => {},
  placeholder = "",
  className = "",
  background = "white",
  onFinish = () => {},
  initialValue = "",
  light = false,
}, ref) => {
  let thisClassName = `${className} ${css.searchBar}`;
  thisClassName += ` ${css[background]}`;
  thisClassName += light ? ` ${css.light}` : "";
  const [value, setValue] = useState(initialValue);
  const [finished, setFinished] = useState(null);


  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
    setValue(newValue) {
      setValue(newValue);
      return newValue;
    },
  }));

  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current){
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className={thisClassName} ref={ref} >
      <MagnifyingGlassSvg/>
      <input ref={inputRef} type="text" placeholder={placeholder} value={value} autoFocus onChange={(event) => {
        setValue(event.target.value);
        onChange(event.target.value);

        //delayed change
        clearTimeout(finished);
        setFinished(setTimeout(() => {
          onFinish(event.target.value);
        }, 300));
      }}/>
      <div className={css.buttonWrapper}>
        <Button className={css.button} label="Search" iconAfter={<CircleArrowRight/>}/>
      </div>
    </div>

  );
});
