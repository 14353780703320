import Head from "next/head";
import React from "react";
import Header from "sections/header/index.jsx";
import Footer from "sections/footer";
import css from "./index.module.scss";

export default function DefaultLayout({
  title,
  description,
  children,
  mainClassName = "",
  light = false,
  schema = null,
  canonical = null,
  shareImageUrl = null,
}) {
  let thisClassName = mainClassName;
  if (light){
    thisClassName += css.light;
  }

  return (
    <div className="root" >
      <Head>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta property="og:description" content={description}/>
        <meta property="og:title" content={title}/>
        {schema && <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}></script>}
        {canonical && <link rel="canonical" href={canonical} />}
        {shareImageUrl && <meta property="og:image" content={shareImageUrl}/>}
      </Head>
      <Header />
      <main className={`${css.main} ${thisClassName}`}>
        {children}
      </main>
      <Footer/>
    </div>
  );
}
