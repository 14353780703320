import React, { forwardRef } from "react";
import css from "./index.module.scss";
import ImageCard from "@/components/imageCard";
import EnterpriseSvg from "/public/images/icons/enterprise.svg";
import Button from "../button";


export default forwardRef(({
  listing = null,
  loading = "lazy",
  sizes = "100vw",
  className = "",
  light = false,
  overlaid = false,
  showPublic = false,
  showEditButton = false,
  href = null, //defaults to the listing page but can be overriden
}, ref) => {
  return <ImageCard
    strapiPicture={listing?.coverArt}
    loading={loading}
    sizes={sizes}
    className={className}
    light={light}
    tag={`${listing?.aiModel?.name} ${listing?.aiModel?.version}` || "AI Prompt"}
    overlaid={overlaid}
    href={href || `/listings/${listing?.slug}`}
    label={<>{listing?.business && <EnterpriseSvg/>}<div>{listing?.name}</div></>}
  >
    {listing?.shortDescription && <div className={css.description}>{listing?.shortDescription}</div>}
    {listing?.price && <div className={css.price}>
      <span>${listing?.price}</span>
      {listing?.purchases && <span>&nbsp;({listing?.purchases?.length} purchase{listing?.purchases?.length !== 1 ? "s" : ""})</span>}
    </div>}
    {showPublic && <div className={css.price}>
      <span>Status: {listing.public ? "public" : "private"}</span>
    </div>}
    {showEditButton && <Button label="Edit" ghost href={`/account/listings/edit?id=${listing.id}`} className={css.editButton} onClick={(e) => e.stopPropagation()}/>}
  </ImageCard>;
});
