import React, { useEffect, useRef, useState } from "react";
import css from "./index.module.scss";
import strapi from "@/scripts/apis/strapi/index";
import Button from "@/components/button";
import ProductCard from "@/components/productCard";
import FloatingTextInput from "@/components/floating-text-input";
import EnterpriseSvg from "/public/images/icons/enterprise.svg";
import PortraitSvg from "/public/images/icons/portrait.svg";
import PenNibSvg from "/public/images/icons/penNib.svg";
import IconsSvg from "/public/images/icons/icons.svg";
import AdSvg from "/public/images/icons/ad.svg";
import ImageSvg from "/public/images/icons/image.svg";
import PaintBrushSvg from "/public/images/icons/paintBrush.svg";
import SearchSvg from "/public/images/icons/search.svg";
import ParagraphSvg from "/public/images/icons/paragraph.svg";
import NewspaperSvg from "/public/images/icons/newspaper.svg";
import AnalyticsSvg from "/public/images/icons/analytics.svg";
import BookSvg from "/public/images/icons/book.svg";
import CodeSvg from "/public/images/icons/code.svg";
import DefaultLayout from "layouts/default";
import Tag from "@/components/tag";
import ImageCard from "@/components/imageCard";
import { sendDatalayerEvent } from "@/scripts/utils";
import RequestPromptForm from "forms/requestPrompt";
import SearchBar from "@/components/searchBar";

export default function Home({initialListings = [], articles = [], featuredListings = []}) {
  const categories = [
    {
      icon: <ParagraphSvg/>,
      label: "Creative Writing",
      description: "Express yourself in an unconventional manner.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"creative+writing\"%2C\"value\"%3A978%7D%5D",
    },
    {
      icon: <NewspaperSvg/>,
      label: "Content Creation",
      description: "Create compelling articles and social media posts.",
      href: "listings?aiModel=&query=social+media+articles&business=&tags=%5B%7B\"label\"%3A\"social+media\"%2C\"value\"%3A1183%7D%5D",
    },
    {
      icon: <SearchSvg/>,
      label: "Research",
      description: "Ask a clever question, get a clever answer.",
      href: "/listings?aiModel=&query=social+media+articles&business=&tags=%5B%7B\"label\"%3A\"research\"%2C\"value\"%3A1161%7D%5D",
    },
    {
      icon: <AnalyticsSvg/>,
      label: "Data Analysis",
      description: "Summarize, sort, and process data.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"data+analysis\"%2C\"value\"%3A1495%7D%5D",
    },
    {
      icon: <CodeSvg/>,
      label: "Programming",
      description: "Speed up your development cycle.",
      href: "/listings?aiModel=1&query=code&business=&tags=%5B%7B\"label\"%3A\"programming\"%2C\"value\"%3A1149%7D%5D",
    },
    {
      icon: <BookSvg/>,
      label: "Education",
      description: "Master a new field with an AI teacher.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"education\"%2C\"value\"%3A1490%7D%5D",
    },
    {
      icon: <PaintBrushSvg/>,
      label: "Visual Art",
      description: "Draw stunning artwork for your latest project.",
      href: "/listings?aiModel=5&query=code&business=&tags=%5B%5D",
    },
    {
      icon: <ImageSvg/>,
      label: "Photography",
      description: "Say goodbye to repetitive stock imagery.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"photorealistic\"%2C\"value\"%3A859%7D%5D",
    },
    {
      icon: <PortraitSvg/>,
      label: "Portraits",
      description: "Flesh out your profile with a custom portrait.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"portrait\"%2C\"value\"%3A1500%7D%5D",
    },
    {
      icon: <PenNibSvg/>,
      label: "Graphic Design",
      description: "Draft stunning logos and mockups.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"graphic+design\"%2C\"value\"%3A1493%7D%5D",
    },
    {
      icon: <IconsSvg/>,
      label: "Iconography",
      description: "Create beautiful icons for your apps and sites.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"iconography\"%2C\"value\"%3A1491%7D%5D",
    },
    {
      icon: <AdSvg/>,
      label: "Advertising",
      description: "Write compelling ad copy.",
      href: "/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"advertising\"%2C\"value\"%3A1492%7D%5D",
    },
  ];

  const searchRef = useRef();

  const [listings, setListings] = useState(null);
  async function updateSearch(query){
    if (query.length > 0){
      sendDatalayerEvent("search", "DL - Search", {query});
      let res = await strapi.query(strapi.endpoints.LISTINGS_SEARCH, {
        data: {
          query: query,
        },
      });
      if (res.listings){
        setListings(res.listings);
      }
    } else {
      setListings(initialListings);
    }
  }

  function addTag(value){
    searchRef.current.setValue(value);
    updateSearch(value);
  }

  return <DefaultLayout
    description="Your source for high-quality AI prompts for ChatGPT, Midjourney, DALL·E 2, GPT-3, Stable Diffusion, and more."
    title="Lazy Prompts"
  >
    <section className={css.fold}>
      <div className="sectionInner">
        <h1>
          <div>Find Your Next</div>
          <div>AI Prompt</div>
        </h1>
        <div className={css.search}>
          <SearchBar placeholder="Search prompts..." onFinish={updateSearch} ref={searchRef}/>
          {listings !== null && <div className={css.results}>
            {listings?.map((listing) => <ProductCard
              key={listing.id}
              listing={listing}
            />)}
          </div>}
          {listings?.length === 0 && listings !== null && <>
            <div className={css.alert}>No prompts found.</div>
            <div className={css.pitch}>Looking for something specific? Send us a prompt request!</div>
            <RequestPromptForm light={false}/>
          </>}
          {listings?.length > 4 && <Button label="View All" ghost grayscale className={css.button}/>}
        </div>
      </div>
    </section>


    <section className={`light ${css.sellPitch}`}>
      <div className="sectionInner">
        <div className={css.imageCell}>
          <div className={css.image}>
            <ImageCard
              picture={{
                base: "/images/misc/money/money",
                widths: [500, 1000, 1500],
                exts: ["webp", "png"],
              }}
              sizes="800px"
              loading="lazy"
              alt="Money"
              overlaid
              light
            />
          </div>
        </div>
        <div>
          <h2>
            <div>Benefits of</div>
            <div>Selling with Us</div>
          </h2>
          <p>Unlike other platforms flooded with irrelevant, low-quality prompts, Lazy Prompts is laser-focused on the needs of enterprise clients. This attention to business-oriented use cases opens up a wealth of opportunities for sellers. When your prompts are tailored to businesses — entities with substantial budgets and a constant need for efficient, AI-driven solutions — you position yourself in a segment with high earning potential.</p>
          <p>At Lazy Prompts, every submission undergoes a meticulous review process, checking for adherence to guidelines, efficacy, and relevance to enterprise use. This rigorous approach helps maintain a high standard across the platform and enhances the value of your prompts. Sellers can take pride in the knowledge that their work is amongst the finest, offering a distinct advantage when attracting discerning enterprise clients.</p>
          <p>Selling is made easy with our streamlined five-minute onboarding process. Our advanced search system, which uses distinct tags and categories, ensures that your prompt reaches the appropriate audience. And let&apos;s not forget the transparency in our pricing model. With Lazy Prompts, you always know what you&apos;re getting: 80% of the prompt price, post fees. No surprises, no hidden charges — just easy money.</p>
          <Button label="Sell With Us" href="/landing/sell"/>
        </div>
      </div>
    </section>

    <section className={`light ${css.news}`}>
      <div className="sectionInner">
        <h2 className="withSubheadline">News</h2>
        <div className={`h2subheadline ${css.subheadline}`}>Explore the latest AI news, enterprise use cases, and prompting techniques.</div>
        <div className={css.cards}>
          {articles.map(((article) => <ImageCard
            key={article.id}
            strapiPicture={article?.attributes?.coverArt?.data?.attributes}
            sizes="(max-width: 1300px) 50vw, (max-width: 650px) 100vw, 33vw"
            light
            href={`/articles/${article?.attributes?.slug}`}
            label={article?.attributes?.headline}
          >{article?.attributes?.subheadline}</ImageCard>))}
        </div>
      </div>
    </section>


    <section className={`${css.buyPitch}`}>
      <div className="sectionInner">
        <div>
          <h2>
            <div>Why Buy a Pre-</div>
            <div>Crafted Prompt?</div>
          </h2>

          <p>Time is one of the most precious commodities in today&apos;s fast-paced world, and pre-crafted AI prompts save you valuable time by providing ready-to-use prompts for a variety of enterprise applications.</p>
          <p>Creating high-quality enterprise prompts requires extensive knowledge of artificial intelligence, data science, and machine learning. Pre-crafted prompts let you take advantage of others&apos; expertise at a fraction of the cost.</p>
          <p>Lazy Prompts reviews each new listing before it goes live to ensure that you get what you&apos;re promised. Prompts with a green checkmark <EnterpriseSvg/> are designed for enterprise use cases.</p>

          <Button label="Buy Enterprise Prompts" href="/listings"/>
        </div>
        <div className={css.imageCell}>
          <div className={css.image}>
            <ImageCard
              picture={{
                base: "/images/misc/robotHandHumanHand/robotHandHumanHand",
                widths: [500, 1000],
                exts: ["webp", "png"],
              }}
              sizes="800px"
              loading="lazy"
              alt="Robot hand touching a human hand"
              overlaid
            />
          </div>
        </div>
      </div>
    </section>

    <section className={`light ${css.useCases}`}>
      <div className="sectionInner">
        <h2 className="withSubheadline">Enterprise Prompt Categories</h2>
        <div className={`h2subheadline ${css.subheadline}`}>Supercharge your business with AI prompts for every use case.</div>
        <div className={css.cases}>
          <ImageCard
            picture={{
              base: "/images/misc/papers/papers",
              widths: [500, 1000],
              exts: ["webp", "png"],
            }}
            sizes="(max-width: 1300px) 50vw, (max-width: 650px) 100vw, 25vw"
            light
            label="Content Creation"
            href={"/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"business+writing\"%2C\"value\"%3A1498%7D%5D"}
          >Create compelling articles and social media posts.</ImageCard>

          <ImageCard
            picture={{
              base: "/images/misc/camera/camera",
              widths: [500, 1000],
              exts: ["webp", "png"],
            }}
            sizes="(max-width: 1300px) 50vw, (max-width: 650px) 100vw, 25vw"
            light
            label="Image Generation"
            href={"/listings?aiModel=7&query=&business=&tags=%5B%5D"}
          >Say goodbye to repetitive stock imagery.</ImageCard>
          <ImageCard
            picture={{
              base: "/images/misc/pen/pen",
              widths: [500, 1000],
              exts: ["webp", "png"],
            }}
            sizes="(max-width: 1300px) 50vw, (max-width: 650px) 100vw, 25vw"
            light
            label="Advertising"
            href={"/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"advertising\"%2C\"value\"%3A1492%7D%5D"}
          >Write compelling ad copy.</ImageCard>
          <ImageCard
            picture={{
              base: "/images/misc/books/books",
              widths: [500, 1000],
              exts: ["webp", "png"],
            }}
            sizes="(max-width: 1300px) 50vw, (max-width: 650px) 100vw, 25vw"
            light
            href={"/listings?aiModel=&query=&business=&tags=%5B%7B\"label\"%3A\"creative+writing\"%2C\"value\"%3A978%7D%5D"}
            label="Creative Writing"
          >Express yourself in an unconventional manner.</ImageCard>
        </div>
      </div>
    </section>

    <section className={`light ${css.featured}`}>
      <div className="sectionInner">
        <h2 className="withSubheadline">Featured Prompts</h2>
        <div className={`h2subheadline ${css.subheadline}`}>The best AI prompts for enterprises, handpicked by our moderator team.</div>
        <div className={css.prompts}>
          {featuredListings.map((listing, idx) => <ProductCard
            light
            key={listing.id}
            listing={listing}
            sizes="(max-width: 1500px) 50vw, 500px"
            overlaid={idx === 0}
          />)}
        </div>
      </div>
    </section>



  </DefaultLayout>;
}
/*
    <section className={`light ${css.categories}`}>
      <div className="sectionInner">
        <h2>Explore by Category</h2>

        <div className={css.grid}>
          {categories.map((category) => <a key={category.label} className={`custom ${css.category}`} href={category.href}>
            <div className={css.icon}>{category.icon}</div>
            <div className={css.label}>{category.label}</div>
            <div className={css.description}>{category.description}</div>
          </a>)}
        </div>
      </div>
    </section>
    */

export async function getStaticProps() {
  //let initialListings = await strapi.query(strapi.endpoints.LISTINGS_FIND, {
  //populate: {
  //coverArt: true,
  //aiModel: true,
  //},
  //filters: {
  //featured: true,
  //},
  //pagination: {
  //pageSize: 4,
  //},
  //});

  let featuredListings = await strapi.query(strapi.endpoints.LISTINGS_FIND, {
    data: {
      id: 17,
    },
    filters: {
      featured: true,
    },
    populate: {
      coverArt: true,
      aiModel: true,
    },
    pagination: {
      pageSize: 5,
    },
  });


  let newestListings = await strapi.query(strapi.endpoints.LISTINGS_FIND, {
    sort: "publishedAt:desc",
    populate: {
      coverArt: true,
      aiModel: true,
    },
    pagination: {
      pageSize: 4,
    },
  });

  let articles = await strapi.query(strapi.endpoints.ARTICLES_FIND, {
    sort: "publishedAt:desc",
    fields: ["id", "headline", "slug", "subheadline", "publishedAt"],
    pagination: {
      pageSize: 3,
    },
    populate: "*",
  });


  return {
    props: {
      featuredListings,
      newestListings,
      articles,
    },
    revalidate: 60,
  };
}
